<template>
  <OnboardingStep
    :title="$t('onboarding.teamAccessSetupView.title')"
    :next-text="$t('onboarding.teamAccessSetupView.nextText')"
    v-bind="$props"
  >
    <div v-if="storeUsesExternalScheduler">
      <I18nArray tag="p" path="onboarding.teamAccessSetupView.paragraphs" />
      <VxBtn outlined @click="refreshEmployeeList">
        <v-icon left>$refresh</v-icon>
        {{ $t("onboarding.teamAccessSetupView.refreshEmployeesButton") }}
      </VxBtn>
    </div>
    <TeamView
      ref="teamViewComponent"
      :use-fab-add-button="false"
      :show-employee-add-button="!storeUsesExternalScheduler"
      v-bind="$props"
    />
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import TeamView from "../Team/AppView";
import { STORE_USES_EXTERNAL_SCHEDULER } from "./graphql";
import { VxBtn } from "@/core-ui";
import I18nArray from "@/components/I18nArray";

export default {
  name: "TeamSetupView",
  components: { TeamView, VxBtn, I18nArray },
  extends: BaseStepView,
  apollo: {
    store: {
      query: STORE_USES_EXTERNAL_SCHEDULER,
      variables() {
        return {
          id: this.storeId,
        };
      },
      skip() {
        return !this.storeId;
      },
    },
  },
  computed: {
    storeUsesExternalScheduler() {
      return this.store?.usesExternalScheduler;
    },
  },
  methods: {
    refreshEmployeeList() {
      this.$refs.teamViewComponent?.refetchEmployees();
      this.$refs.teamViewComponent?.refetchDrsEmployees();
    },
  },
};
</script>
